
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
.aradugbo-slider .slick-list {
    height:300px;
    width:1920px;
   
}



@media screen and (max-width: 640px) {
    .aradugbo-slider .slick-list {
        height:250px;
    }
}




     .people {
         width: 2090px;
     }




@media screen and (min-width: 1601px) {
   .aradugbo-slider .people {
        width: 1990px;
    }
   .aradugbo-slider .people-row {
        margin-right: 0;
    }
}
@media screen and (max-width: 1600px) {
    .aradugbo-slider .people {
        width: 1700px;
    }
    .aradugbo-slider .people-row {
        margin-right: 0;
    }
    .aradugbo-slider .people-row div {
        margin-right: 14px;
    }
    
}
@media screen and (max-width:470px) {

    .about_container {
        transform: translateY(-20%);
    }
    .about_img {
        transform: translateX(10%);
    }

    .about_container img {
       min-width: 617px;
        height:430px;
    }
    

    .people-row div {
        margin-right: 23px;
    }
    #people-row-1 {
        margin-bottom: 20px;
    }

    #people-row-2 div:nth-child(1){
        display:none;
    }

}
@media screen and (max-width:420px) { 
    .about_img {
        transform: translateX(3%);
    }

    .about_container img {
       min-width: 617px;
        height:430px;
    }
   
}

@media screen and (max-width:375px) {
    .about_container {
        transform: translateY(-10%);
    }
    .about_img {
        transform: translateX(3%);
    }

    .about_container img {
       min-width: 617px;
        height:430px;
    }
   
}

@media screen and (max-width:369px) {
    .btn_container {
        width: 135px;
        margin-right: 6px;
    }
    
    .join_button div:nth-child(2){
        margin-right: 0px;
    }

    .btn_container img {
        margin-right: 6px;
    }
   
}

