.login-container {
    background: linear-gradient(269.48deg, #FCBE5A -14.66%, #FC5059 38.46%);
    border-radius: 28px;
}
.bg-pry {
    background: linear-gradient(269.48deg, #FCBE5A -14.66%, #FC5059 38.46%);
}
.hamburger-container {
    display: none;
}

.navlink-container li {
    color:#F2F4F7;
}

@media screen and (min-width:920px) {
    .sidebar-container {
        display: none;
    }
}

@media screen and (max-width:920px) {
    .navlink-container {
        display: none;
    }
    .hamburger-container {
        display: block;
    }
}

.header.fixed {
    background:white;
    transition: .3s ease-in-out;
}

