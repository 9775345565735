
.verify-input-field{
    margin-top:36px;
}

.verify-input-field input {
    border:1px solid #F04438;
    border-radius: 5px;;
    height: 45px;
    width:42px;
    margin-right:8px;
    text-align: center;
    outline:none;
    
}

.verify-input-field input::-webkit-inner-spin-button,
.verify-input-field input::-webkit-outer-spin-button {
    display:none;
}