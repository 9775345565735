.subscribe_btn {
    width: 129px;
    height: 48px;
    border:none;
    outline: none !important;
    background: linear-gradient(269.48deg, #FCBE5A -14.66%, #FC5059 38.46%);
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 100px;
    cursor: pointer;
}

.pagination {
    width:100%;
    list-style: none;
    display: flex;
    align-items: center;
    margin-bottom: 5rem;
    gap:5;
}

.dark {
    color:#F9FAFB;
}

.light {
    color:#475467;
}

.pagination .previous {
    width:100%;
    display:flex;
    align-items: center;
    justify-content: flex-start;
} 

.pagination .page-num{
    width:40px;
    height:40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}
.pagination .page-num.active {
    background:#F9FAFB;
    color:#1D2939;
}

.pagination .next {
    width:100%;
    display:flex;
    justify-content: flex-end;
}

@media screen and (max-width: 430px) {
    .header-text h2{
        font-size: 30px;
    }
    .header-text p{
        font-size: 16px;
    }
}
